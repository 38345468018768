<template>
    <div>
        <v-img :src="src" 
            style="width:100px;" 
            @touchstart="active=true" 
            @touchend="active=false"    
            class="mx-auto"> 
        </v-img>      
    </div>
</template>
<script>
export default {
    data () { return {
        active: false,
    }},
    computed: {
        src: function () { 
            return !this.active ? '/img/si_1.svg' : '/img/si_2.svg'
        }
    },
}
</script>